.StatCard .Icon {
  margin-top: 20px;
  margin-left: 20px;
  width: 50%;
  height: 50%;
}
.StatCard .Title {
  margin-top: 25px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: normal;
}

.StatCard .Description {
  font-size: 1.875rem;
  line-height: normal;
  font-weight: 700;
}

.StatCard {
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  /* border: 1px solid var(--light); */
  background-color: rgba(255, 236, 236, 1);
}

@media screen and (max-width: 576px) {
  .Icon {
    display: none;
  }
}
