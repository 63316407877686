.center {
  padding: 10px;
}

.SignInForm {
  width: 450px;
  /* height: 350px; */
  box-sizing: border-box;
  background-color: #fff;
}

.FormHeaderText {
  margin-bottom: 0px;
  text-align: center;
}

.Background {
  width: 100%;
  height: 100vh;
  background-image: url("./assets/background.webp");
  background-size: cover;
}

.Input {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  line-height: 42px;
  color: black;
}

.Input:focus {
  border: 1px solid black;
}

/* .w-93 {
  width: 93% !important;
} */

.inputBoxHeight46 {
  height: 46px !important;
}

/* .logo {
  transform: scale(1);
} */

::placeholder {
  color: #949494;
}

::-webkit-input-placeholder {
  font-size: 15px !important;
}

@media only screen and (max-width: 600px) {
  .SignInForm {
    width: 370px;
    height: auto;
    box-sizing: border-box;
    background-color: white;
    /* box-shadow: 1px 1px 1px 1px lightgray; */
  }

  .Input {
    line-height: 35px;
  }

  /* .logo {
    transform: scale(0.7, 0.7);
  } */
}


@media (max-width: 991.98px) {
  .Background,.h-m-100{
    height: 100%;
  }
}