/* Profile Avatar */
.profileList_ul .ant-avatar-group > *:not(:first-child) {
  margin-inline-start: -18px;
}

.profileList_ul .ant-avatar-group .ant-avatar {
  border: 1.5px solid #fff !important;
}

.antdAvatar,
.antdAvatar * {
  font-family: "Ubuntu";
}

@media screen and (max-width: 767.98px) {
  .profileList_ul {
    align-self: center;
  }
  .newList {
    align-self: center !important;
  }
}

.newList {
  background-color: #589dc2;
  border-radius: 5px;
  /* width: fit-content; */
  align-self: flex-start;
}
