.searchBar {
  display: inline-flex;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--primary-opacity);
  border-radius: 6px;
  height: 38px;
}
.searchBar svg[width="16"][height="16"]{
  margin-top: -2px;
}
.searchBar .searchInput {
  background-color: var(--white);
  padding-left: 10px;
  height: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary);
  padding-right: 30px;
}

.searchInput::placeholder {
  font-weight: 300;
  font-size: 14px !important;
  color: #949494;
}

.searchBar .searchIcon {
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-25%, -50%);
  right: 0;
}

.searchBar .searchIcon * {
  stroke: var(--primary);
}

.searchBar .crossIcon svg {
  position: absolute;
  top: 50%;
  transform: translate(-25%, -50%) scale(0.8);
  right: 0;
  cursor: pointer;
}
