html,
body,
#root,
.ant-app {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "ff_rg", sans-serif !important;
  font-size: 16px !important; */
  /* line-height: 1.5 !important; */
  color: var(--dark) !important;
}
* {
  border: none;
  outline: none;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}

.task-description a {
  text-decoration: underline !important;
  color: blue !important;
}

.ff {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
}

.ff .ant-dropdown-menu-item {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
}

.ff_lt {
  font-weight: 300;
}

.ff_rg {
  font-weight: 400;
}

.ff_md {
  font-weight: 500;
}

.ff_bd {
  font-weight: 700;
}

.f40 {
  font-size: 40px;
  /* line-height: 48px; */
}

.f38 {
  font-size: 38px;
  line-height: 3.093rem;
}

.f34 {
  font-size: 34px;
  line-height: 44px;
}

.f30 {
  font-size: 1.875rem;
  line-height: 2.188rem;
}

.f24 {
  font-size: 1.5rem;
  line-height: 1.724rem;
}

.f20 {
  font-size: 1.25rem;
  /* line-height: 1.436rem; */
}

.f18 {
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.f16 {
  font-size: 1rem;
  line-height: 1.25rem;
}

.f14 {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.f12 {
  font-size: 0.75rem;
  line-height: 1rem;
}

.f10 {
  font-size: 0.625rem;
  line-height: 0.781rem;
}

.f11 {
  font-size: 11px;
  line-height: normal;
}

.w-80 {
  width: 80% !important;
}

.w-93 {
  width: 93% !important;
}

.minHeight46 {
  height: 2.875rem !important;
}

.border-5 {
  border-radius: 5px !important;
}

.border-s {
  border: 1px solid #e5e5e5;
  border-radius: 5px !important;
}

::placeholder {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px !important;
  color: #949494 !important;
}

/* AntD Modal */

.antModalDivider {
  height: 1px;
  background-color: #d9d9d9;
}

.ant-picker-outlined:hover {
  border-color: #d9d9d9 !important;
}

.ant-picker-outlined:focus-within {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-outlined {
  border: none;
}

.ant-modal-content {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
@media (max-width: 575.98px) {
  .ant-modal-content {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    padding: 20px 14px !important;
  }
}
.ant-picker-input > input::placeholder {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
  font-weight: 300 !important;
  font-size: 14px !important;
}

.cursor-pointer {
  cursor: pointer;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.dragging {
  border: 2px dashed #1890ff; /* thicker border */
  padding: 30px; /* more padding */
  text-align: center;
  vertical-align: middle;
  transition: background-color 0.3s ease;
  background-color: #f0f0f0;
  width: 755px;
  /* height: 450px; */
  height: calc(100vh - 90px);
  line-height: 465px;
  font-weight: bold;
  overflow-y: scroll;
}

.ck-editor__editable {
  min-height: 99px;
}

.btn-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Additional styles as needed */
}

.spinner-border {
  width: 1.5rem; /* Adjust size as needed */
  height: 1.5rem; /* Adjust size as needed */
  /* Additional styles as needed */
}

.centered-popconfirm .ant-popconfirm-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-lg {
  --bs-btn-font-size: 1rem;
}
.form-control-lg {
  font-size: 1rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-primary {
  border-radius: 5px;
  --bs-btn-bg: #1f63aa;
  --bs-btn-border-color: #1f63aa;
}

.tomorrow-button {
  width: 100%;
  border: none;
  height: auto;
  box-shadow: none;
  padding: 0;
  font-size: 0.75rem;
  text-align: left;
}
@media (max-width: 575.98px) {
  .f30 {
    font-size: 1.5rem;
    line-height: normal;
  }
}

@media (max-width: 991.98px) {
  body {
    margin: 0;
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    box-sizing: border-box;
  }
  html,
  body,
  #root,
  .ant-app {
    height: 100%;
  }
  .ant-message.ant-message-top {
    top: env(safe-area-inset-top) !important;
  }
}

@supports (padding: env(safe-area-inset-top)) {
  body {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
