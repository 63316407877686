.NavigationBar2 {
  width: 96px;
  /* height: 42px; */
  text-decoration: none !important;
  color: inherit;
  align-items: center;
}

.NavigationLink2 {
  /* width: 54px; */
  height: 48px;
  border-radius: 5px;
}

.NavigationLink2.active {
  background: #1f63aa;
}

/* .labelContainer {
  color: rgba(109, 109, 109, 1);
  width: 120px;
}

.NavigationLink2.active .labelContainer {
  color: aliceblue;
} */

.NavIconBG {
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  background-color: white;
  border: 1px solid #6d6d6d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavIconBG2 {
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  background-color: #f2f2f2;
  /* border: 1px solid #6d6d6d; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavigationLink2.active .NavIconBG {
  border: 0;
}

.NavigationLink2.active .NavIconBG > svg > g > path {
  fill: #1f63aa;
}

.NavigationLink2.active .NavIconBG > svg > path {
  fill: #1f63aa;
}

.adminInfo2 {
  position: absolute;
  /* width: inherit; */
  width: 96px;
  bottom: 0px;
  /* left: 0px; */
  background-color: #1f63aa;
}

.innerNavigationLink.active > div {
  /* background-color: #f0f7ff; */
}
.ant-wave{
  box-shadow: none !important;
}
.innerNavigationLink.active .NavIconBG2 > label {
  color: #1f63aa !important;
}


@media (max-width: 991.98px) {
  .sidebarDesktopArrow{
    display: none;
  }
}