.NavigationBar .NavigationLink {
  width: 200px;
  height: 42px;
  text-decoration: none !important;
  color: inherit;
  align-items: center;
}

.NavigationLink {
  border-radius: 5px;
}

.NavigationLink.active {
  background: #1f63aa;
}

.labelContainer {
  color: rgba(109, 109, 109, 1);
  width: calc(100% - 4.5rem);
}

.NavigationLink.active .labelContainer {
  color: aliceblue;
}
.NavIconBG {
  width: 2rem;
  height: 2rem;
  background-color: white;
  border: 1px solid #6d6d6d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavigationLink.active .NavIconBG {
  border: 0;
}

.NavigationLink.active .NavIconBG > svg > g > path {
  fill: #1f63aa;
}

.NavigationLink.active .NavIconBG > svg > path {
  fill: #1f63aa;
}

.NavigationLink.active .plusIcon > svg > rect:nth-child(1) {
  fill: white;
}

.NavigationLink.active .plusIcon > svg > rect:nth-child(2) {
  fill: #1f63aa;
}
.NavigationLink.active .plusIcon > svg > rect:nth-child(3) {
  fill: #1f63aa;
}

.adminInfo {
  /* position: absolute; */
  width: 100%;
  /* bottom: 0px; */
  /* left: 0px; */
  background-color: #1f63aa;
}

.innerNavigationLink.active > label {
  color: #1f63aa !important;
}

.w-90 {
  width: 90% !important;
}

.w-92 {
  width: 98% !important;
}


.innerNavigationLink{
  text-align: center;
}

.customNavigation .NavigationBar {
  flex: 1 1 auto;
}