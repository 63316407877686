.customNavigation {
  width: 16rem;
  flex-basis: 16rem;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 999;
  transform: translateX(-16rem);
  -webkit-transform: translateX(-16rem);
  -moz-transform: translateX(-16rem);
  -ms-transform: translateX(-16rem);
  -o-transform: translateX(-16rem);
}
.main-page-content {
  flex-grow: 1;
}
.deviceHeader {
  z-index: 999;
}
.menu-btn {
  background-color: #f1f1f1;
  width: 2.25rem;
  height: 2.25rem;

  position: relative;
}
.menu-btn span {
  display: block;
  height: 0.125rem;
  width: 1.5rem;
  background: #1f63aa;
  transition: all 0.15s ease;
  position: absolute;
  left: 0.5rem;
  transform: rotate(0deg);
}

.menu-btn span:first-child {
  width: 1.25rem;
  top: 0.875rem;
}
.menu-btn span:last-child {
  width: 0.875rem;
  top: 1.35rem;
}

.menu-btn.menubtn-on span {
  top: 1.0875rem;
}
.menu-btn.menubtn-on span:first-child {
  transform: rotate(45deg);
}
.menu-btn.menubtn-on span:last-child {
  transform: rotate(-45deg);
  width: 1.25rem;
}

@media (min-width: 992px) {
  .customNavigation {
    transform: translateX(0);
  }
}
@media (max-width: 991.98px) {
  .customNavigation {
    position: fixed;
    height: calc(100% - env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
    bottom: env(safe-area-inset-bottom);
  }
  .main-page-content {
    margin-top: 3rem;
  }
  .customNavigation.openNavigation {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}
.View {
  height: 100vh;
  overflow: hidden;
}

.NavigationPanel {
  display: flex;
  justify-content: center;
  background: white;
}

.NavigationPanel2 {
  display: flex;
  max-width: 6rem;
  justify-content: center;
  background: white;
}

.adminDashboardItems .StatCard > div {
  min-height: 84px !important;
  height: 100%;
}

.label {
  border-radius: 5px;
}

.newList {
  background-color: #589dc2;
  border-radius: 5px;
  width: fit-content;
  align-self: flex-start;
}
.addIcon38 {
  width: 38px;
  height: 38px;
}

.ant-dropdown-trigger .ant-btn {
  width: 38px;
  height: 38px;
}

.TaskMajorTitle .ant-dropdown-trigger .ant-btn {
  width: 1rem;
  height: 1rem;
  border: 0;
  color: #ffffff !important;
  box-shadow: none !important;
  line-height: 0;
  padding: 0;
}
.customNavigation .ant-dropdown-trigger .ant-btn {
  width: 1.5rem;
  height: 1rem;
  border: 0;
  padding: 0;
  line-height: 0;
}
.dashboard-container.subContainer {
  height: calc(100vh - 17rem);
  overflow: hidden;
  padding-bottom: 1rem;
}
.project-container.subContainer {
  height: calc(100vh - 7rem);
  overflow: hidden;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  /* .DashboardHeader .dashBoardTitle {
    text-align: start !important;
  } */

  .View {
    overflow: hidden;
  }

  .draggableCard {
    height: 500px;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 576px) {
  .Dashboard .ant-picker {
    margin-left: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  /* .DashboardHeader .dashBoardTitle {
    text-align: center;
    font-size: 22px;
    line-height: 29px;
  } */

  /* .View {
    overflow: scroll;
  } */
}

@media screen and (min-width: 768.02px) {
  /* .subContainer {
    height: 100% !important;
  } */
}

.employeeListAndSearch {
  width: calc(100% - 0.75rem);
}

.minWidth125 {
  min-width: 125px !important;
}

.minWidth100 {
  min-width: 100px !important;
}

.minWidth85 {
  min-width: 85px !important;
}

.h40 {
  height: 40px !important;
}

.h32 {
  height: 32px !important;
}
.h38 {
  height: 38px !important;
}

.h34 {
  height: 34px !important;
}

.h36 {
  height: 36px !important;
}

.w642 {
  width: 40.125rem;
}

.responsiveModalLabel {
  line-height: normal;
  max-width: 85%;
  margin: 0 auto;
}

.ant-select-selector {
  border: none !important;
}

.ant-select {
  border: 1px solid #1f63aa;
  border-radius: 6.5px;
}

.searchableSelect .ant-select {
  border: 1px solid #e5e5e5;
  border-radius: 6.5px;
  height: 2.438rem;
}

.searchableSelect .ant-select-selector {
  padding-left: 1rem !important;
}

.searchableSelect .ant-select-selection-search {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  padding-left: 0.3rem !important;
}

.searchableSelect .ant-select-selection-placeholder {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
  font-size: 15px !important;
  color: var(--light-sm) !important;
  font-weight: 300 !important;
}

.searchableSelect .ant-select-selection-item {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
}

.addMemberButton {
  height: 50px;
  margin-top: 25px;
}

.addMemberToTaskButton {
  height: 40px;
  margin-top: 25px;
}

.addMemberToTaskDropdown {
  width: 200px;
}

.ant-picker {
  height: 32px !important;
  /* margin-left: 1rem !important; */
  margin-left: 0;
}

.antDatePicker {
  margin: 0 !important;
}

.ant-picker-suffix > span > svg {
  fill: #1f63aa;
}

.ant-select-arrow > span > svg {
  fill: #1f63aa;
}

/* .ant-modal .ant-modal-close {
  width: 1.625rem !important;
  height: 1.625rem !important;
  margin-right: 0.313rem !important;
  padding-bottom: 0.313rem !important;
} */

.ant-modal .ant-modal-close:hover {
  background-color: #fff !important;
}

.ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1.5);
}

.modelMaxHeight .ant-modal-content {
  max-height: min(80vh, 700px);
  /* max-height: 500px; */
  overflow-y: scroll;
}

.modelMaxHeight .ant-modal-content::-webkit-scrollbar {
  border-radius: 0px 6px 6px 0px !important;
  background-color: #fff !important;
}

.taskDetailModel .ant-modal-content {
  /* max-height: min(80vh, 700px); */
  /* max-height: 500px; */
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 50px);
}

.taskDetailModel .ant-modal-content::-webkit-scrollbar {
  border-radius: 0px 6px 6px 0px !important;
  background-color: #fff !important;
}
@media (max-width: 991.98px) {
  .taskDetailModel .ant-modal-content {
    width: 100%;
    margin: 0 !important;
  }
}
/* Task Component */
/* .TaskCompo {
  min-width: 18.438rem;
  max-width: 28.125rem;
  height: fit-content;
  height: 100%;
  background-color: white;
  overflow-y: auto;
} */

.TaskCompo {
  width: 300px !important;
  flex: 0 0 300px;
  height: 100%;
  background-color: white;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.task-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

.task-count-text {
  margin: 0;
  font-size: 15px;
  color: white;
}

.TaskMajorTitle {
  height: 38px;
  border-radius: 5px 5px 0px 0px;
  /* background-color: #b5b5b5; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

/* .TaskMajorTitle > p {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
} */

.addCard > p {
  cursor: pointer;
}

.InfoCard {
  height: fit-content;
  background-color: #f6f6f6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /*border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  */
}

.draggableCard {
  flex: 1 1 auto;
  overflow-y: auto;
}

/* Custom DropDown */
.customDropDown {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  /* width: 5.473rem !important; */
  height: 2.413rem !important;
  color: #000000 !important;
  padding-left: 1rem;
}

.h100 {
  height: 6.25rem !important;
}

.w400 {
  width: 25rem !important ;
}

.adminInputBoxLarge {
  height: 6.25rem !important;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  resize: none;
  padding-left: 14px;
  padding-top: 10px;
}

.form-input-type {
  position: relative;
}

.form-check-input:checked {
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: var(--primary);
}

.bg-white {
  background-color: #f0f0f0 !important;
}
.bg-ffffff {
  background-color: #ffffff;
}
.bg-white span {
  color: var(--primary) !important;
}
.sticky-bottom.bg-ffffff {
  bottom: 0;
  z-index: 0;
}
/* .adminLargeInput > textarea::placeholder {
  font-family: Ubuntu_light;
  font-size: 14px !important;
} */

/* .addToCard > textarea::placeholder {
  font-family: Ubuntu_light;
  font-size: 14px !important;
} */

.adminAntModelAddButton {
  /* height: 46px; */
  width: 90px !important;
  display: inline-block;
  background-color: #1f63aa;
  border-radius: 5px;
}

.minWidth146 {
  width: 7rem !important;
}

.minWidth120 {
  width: 7.5rem !important;
}

.minWidth356 {
  width: 22.25rem !important;
}

.inputBoxWidth246 {
  width: 16.5rem !important;
}

.inputBoxWidth150 {
  width: 9.375rem !important;
}

.customDropdownData {
  width: 5.5rem;
  display: none;
  z-index: 1;
  border-radius: 5px;
}

.customDropdownData.is-active {
  display: block;
  max-height: 80px;
  /* overflow-y: scroll; */
}

.customDropdownData input {
  width: 0;
  height: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}

.customDropdownLabel:last-child {
  margin-bottom: 0 !important;
}

.addMemberIcon {
  /* border: 1px solid; */
  border-radius: 75px;
}

.addToCard {
  width: fit-content;
  height: fit-content;
  background-color: #f0f7ff;
  border-radius: 0.625rem;
}

.addToCardData {
  /* height: 2.25rem; */
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.textEditor {
  height: auto;
  /* border: 1px solid #dcdfe4; */
  border-radius: 5px;
}

.attachment input {
  display: none;
}

.attachment .attachmentStyle {
  min-height: 150px;
  /* width: 24rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(31, 99, 170, 1)' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 12px;
  cursor: pointer;
}

/* .attachment {
  width: 24.5rem;
} */

.attachmentStyle {
  background-color: #f6f6f6;
  padding-left: 25px;
}
.addTOcard_mobile {
  background-color: #f0f7ff;
  border-radius: 0.625rem;
}
.upload-w-100 .ant-upload {
  width: 100%;
}
@media (max-width: 575.98px) {
  .addToCard.addTOcard_mobile {
    width: 100% !important;
    height: auto !important;
  }
  .addTOcard_mobile .ant-upload-wrapper,
  .addTOcard_mobile .addToCardData {
    width: 47%;
    flex: 0 0 47%;
    /* margin: 0 auto; */
    margin-bottom: 0 !important;
  }
  .addTOcard_mobile .ant-upload-wrapper .ant-upload .addToCardData,
  .addTOcard_mobile .ant-upload-wrapper .ant-upload {
    width: 100%;
  }
  .addTOcard_mobile .flex-sm-column.flex-row {
    flex-wrap: wrap;
  }
}

.col-md-9-5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  width: 80%;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--light);
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

@media screen and (min-width: 600.5px) {
  .customDropDown {
    height: 2.875rem !important;
  }

  .searchableSelect .ant-select {
    height: 2.875rem !important;
  }
}

.smallDropDown {
  width: 5.473rem !important;
}

.selectProjectName {
  color: var(--primary);
}

/* Custom Pop Up */
.customPopup {
  display: none;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(52, 58, 63, 0.3);
  box-shadow: 0px 6px 14px rgb(52 58 63 / 8%);
  z-index: 9;
}

.customPopup.is-active {
  display: block;
}
.customPopup .form-check-input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.customPopup.is-active {
  max-height: 150px;
  overflow-y: scroll;
}

.projectData {
  position: absolute;
  width: 100%;
  box-shadow: 0px 6px 14px rgba(52, 58, 63, 0.08);
  display: none;
  z-index: 9;
}

.projectData.is-active {
  display: block;
  max-height: 150px;
  overflow-y: scroll;
  padding-bottom: 0 !important;
}

/* .projectData {
  visibility: hidden;
} */

.projectData label:last-child {
  margin-bottom: 0.5rem !important;
}

.projectLabel.is-active {
  color: var(--secondary);
}

/* Add New List Component */
.addNewList {
  line-height: 25px;
}

.smallContainer {
  width: 5px !important;
}

.ma_verticalLine {
  display: inline-block;
  flex: 0 0 0.25rem;
  vertical-align: middle;
  width: 0.25rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 5px;
}

.StatusView {
  border-top-left-radius: 0.313rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0.313rem !important;
}

.ListView {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0.313rem !important;
  border-bottom-right-radius: 0.313rem !important;
  border-bottom-left-radius: 0px !important;
}
.addcardImgIcon:first-child {
  margin-left: 0;
}
.addcardImgIcon {
  align-items: center;
  margin-left: 5px;
  white-space: nowrap;
}
.addcardImgIcon img {
  width: 0.875rem;
  height: 0.875rem;
  object-fit: contain;
}

@media (max-width: 575.98px) {
  .mob-w-100 {
    width: 100%;
  }
  .InputBox.inputBoxWidth150 {
    width: 100% !important;
    margin: 0 !important;
  }
}
.checkListcheckbox .form-check-input {
  margin-left: -1.25rem;
  margin-top: 7px;
}
.checkListcheckbox .form-check-input:checked {
  border-color: #1f63aa;
  background-color: #1f63aa;
}

/* Preview Modal Style  */
.previewModal {
  width: auto !important;
  max-width: 100%;
  height: 100vh;
  overflow: hidden;
}
.previewModal::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 72%);
}
.previewModal .ant-modal-content {
  height: calc(100% - 7rem);
  margin: 2rem 0;
  background: transparent;
  box-shadow: none;
}
.previewModal .ant-modal-content .ant-modal-body {
  /* overflow: auto; */
  height: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.nextprevBtn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff !important;
}
.nextprevBtn.prevBtn {
  left: 1rem;
}
.nextprevBtn.nextBtn {
  right: 1rem;
}
.nextprevBtn[disabled] {
  opacity: 0;
}

.previewModal .ant-modal-close {
  position: fixed;
  right: 1rem;
  top: 1rem;
  background-color: #ffffff;
  border-radius: 100%;
  opacity: 1;
}
.downloadBtn {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}
/* Preview Modal Style  */

.InputBox.w-100 {
  width: 100% !important;
}
@media (max-width: 575.98px) {
  .customSearch-row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .customSearch-row .col-50 {
    width: 48%;
    flex: 0 0 48%;
  }
  .customSearch-row .col-100 {
    width: 100%;
    flex: 0 0 100%;
  }
  .taskDetailModel .ant-modal-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .attachmentsBox {
    width: 100px !important;
    height: 100px !important;
  }
  .attachmentFileName {
    width: 100px !important;
  }
  .nextprevBtn {
    width: 2rem;
    height: 2rem;
  }
}

.css-13cymwt-control,
.css-5xvsyi-control:hover,
.css-1u3hri5-control {
  border-color: #dee2e6 !important;
}

.memberDropdownList {
  top: -100%;
}
.projectEmpColumn {
  width: 100%;
}

.ph-column-210 {
  width: 170px;
  flex: 0 0 170px;
}
.projectMemberList .projectMember {
  margin-right: -8px !important;
  border: 1px solid #ffffff;
}
@media (max-width: 1599.98px) {
  .ph-column-210 {
    width: 160px;
    flex: 0 0 160px;
  }
  .dashBoardTitle.f30 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1399.98px) {
  .dashBoardTitle.fs-4 {
    font-size: 1rem !important;
  }
  .projectEmpColumn {
    font-size: 0.875rem;
  }
  .ph-column-210 {
    width: 150px;
    flex: 0 0 150px;
  }
}
@media (max-width: 1299.98px) {
}
@media (max-width: 1199.98px) {
  .memberColumn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .memberColumn {
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .dashBoardTitle.f30 {
    font-size: 1.25rem;
  }
  .memberColumn {
    width: 100%;
    flex: 0 0 100%;
  }
  .ph-column-210-mob,
  .ph-column-210 {
    width: 49.25%;
    flex: 0 0 auto;
  }
  .projectMobileDiv {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.25s all;
    -webkit-transition: 0.25s all;
    -moz-transition: 0.25s all;
    -ms-transition: 0.25s all;
    -o-transition: 0.25s all;
  }
  .projectMobileDiv.show {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
  .projectHeaderRow {
    width: 100%;
  }
  .dashboard_emp_date_row {
    flex-wrap: wrap;
  }
  .empColumn {
    width: 50%;
  }
  .dateColumn {
    width: 45%;
  }
  .p_taskTitle {
    order: 1;
    font-size: 1.25rem;
    margin-top: 0.5rem;
    display: inline-block !important;
    width: auto;
  }
  .btn-Right {
    margin-left: auto;
    z-index: 1;
  }
}
@media (min-width: 1400px) {
  .projectHeaderRow .col-xxl-3.gridListColumn {
    width: 21%;
  }
}
.mention {
  background-color: #4287f5;
  border-radius: 5px;
  padding: 0 5px;
  color: #fff;
}

.taskModalTextarea {
  line-height: 1.25 !important;
}

@media (max-width: 991.98px) {
  .View {
    height: 100%;
  }
  .deviceHeader.top-0 {
    top: env(safe-area-inset-top) !important;
  }
  .vw-100 {
    width: 100% !important;
  }
}

/* AnalyticsTable.css */
.custom-table .ant-table-thead > tr > th {
  background-color: #589dc2; /* Header background color */
  color: white; /* Header text color */
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
}

.custom-table .ant-table-tbody > tr:hover {
  background-color: #f5f5f5; /* Row hover effect */
}

.custom-table .ant-table-tbody > tr > td {
  color: #333; /* Row text color */
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif !important;
}


.dashboard-container.analyticsContainer {
  height: calc(100vh - 4rem);
  overflow: hidden;
  padding-bottom: 1rem;
}



