*, ::after, ::before {
    box-sizing: border-box;
}
body{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}
.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}
.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
.mb-2 {
    margin-bottom: .5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}
.mb-1 {
    margin-bottom: .25rem !important;
}
label {
    display: inline-block;
}
.h-100 {
    height: 100% !important;
}
.position-relative {
    position: relative !important;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img, svg {
    vertical-align: middle;
}
.rounded-2{
    border-radius: 0.375rem !important;
}
.z-1 {
    z-index: 1 !important;
}
.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
}
.end-0 {
    right: 0 !important;
}
.position-absolute {
    position: absolute !important;
}
.opacity-50 {
    opacity: .5 !important;
}
.text-primary{
    color: #1f63aa !important;
}
.text-danger{
    color: rgb(220,53,69);
}
.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
button:focus:not(:focus-visible) {
    outline: 0;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1f63aa;
    --bs-btn-border-color: #1f63aa;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1f63aa;
    --bs-btn-disabled-border-color: #1f63aa;
}
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.spinner-border,.spinner-grow{
    display:inline-block;
    width:var(--bs-spinner-width);
    height:var(--bs-spinner-height);
    vertical-align:var(--bs-spinner-vertical-align);
    border-radius:50%;
    animation:var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}
@keyframes spinner-border{
    to{
        transform:rotate(360deg)
    }
}
.spinner-border{
    --bs-spinner-width:1.5rem;
    --bs-spinner-height:1.5rem;
    --bs-spinner-vertical-align:-0.125em;
    --bs-spinner-border-width:0.20em;
    --bs-spinner-animation-speed:0.75s;
    --bs-spinner-animation-name:spinner-border;
    border:var(--bs-spinner-border-width) solid currentcolor;
    border-right-color:transparent
}
.spinner-border-sm{
    --bs-spinner-width:1rem;
    --bs-spinner-height:1rem;
    --bs-spinner-border-width:0.2em
}
@keyframes spinner-grow{
    0%{
        transform:scale(0)
    }
    50%{
        opacity:1;
        transform:none
    }
}
.spinner-grow{
    --bs-spinner-width:1rem;
    --bs-spinner-height:1rem;
    --bs-spinner-vertical-align:-0.125em;
    --bs-spinner-animation-speed:0.75s;
    --bs-spinner-animation-name:spinner-grow;
    background-color:currentcolor;
    opacity:0
}
.spinner-grow-sm{
    --bs-spinner-width:1rem;
    --bs-spinner-height:1rem
}
@media (prefers-reduced-motion:reduce){
    .spinner-border,.spinner-grow{
        --bs-spinner-animation-speed:1.5s
    }
}
@media (min-width: 576px) {
    .mt-sm-5 {
        margin-top: 3rem !important;
    }
}