.date-time-range-picker-modal .ant-modal-content {
  height: calc(100% + 35px) !important;
  display: flex;
  flex-flow: column;
}

.ant-picker.ant-picker-range.w-100.date-time-range-picker {
  background-color: transparent;
  border: none !important;
}

.ant-picker.ant-picker-range.w-100.date-time-range-picker.ant-picker-focused {
  box-shadow: none;
}

.custom-date-time-picker-popup .ant-picker-panel-container {
  box-shadow: none;
}

.date-time-range-picker-modal .ant-modal-footer {
  margin-top: auto;
}

.ant-picker-panel-container .ant-picker-footer {
  display: none;
}

.date-time-range-picker-modal .ant-picker.custom-date-time-picker {
  visibility: hidden;
  height: 0;
  width: 0;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--secondary) !important;
}

.ant-picker-cell-inner {
  font-family: "Ubuntu" !important;
  font-weight: 400 !important;
}

.ant-picker-month-btn {
  font-family: "Ubuntu" !important;
  font-weight: 700 !important;
}

.ant-picker-year-btn {
  font-family: "Ubuntu" !important;
  font-weight: 700 !important;
}

.ant-picker-content > thead > tr > th {
  font-family: "Ubuntu" !important;
  font-weight: 500 !important;
}

.ant-picker-cell-today {
  border-color: var(--secondary) !important;
}

.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-today {
  border-color: var(--secondary) !important ;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 6px 15px !important;
}
