:root {
  --primary: #1e1e1e;
  --secondary: #1f63aa;
  --light: #f3f9fd;
  --dark: #090909;
  --light-primary: rgba(233, 242, 248, 1);
  --white: #ffffff;
  --border-radius-large: 18px;
  --border-radius-normal: 12px;
  --border-radius-small: 5px;
  --success: rgba(25, 115, 44, 1);
  --error: rgba(209, 15, 15, 1);
  --primary-opacity: rgb(14 52 75 / 10%);
  --b-shadow: rgba(149, 157, 165, 0.1) 0px 0px 24px;
  --yellow: #ffd43d;
  --lightBlue: #d9edf8;
  --lightRed: #ffecec;
  --lightGreen: #e4f1ee;
  --lightPurple: #dedaf4;
  --blue: #0e6696;
  --red: #a90101;
  --green: #009170;
  --purple: #22127c;
  --light-sm: #949494;
  --pending: #b5b5b5;
  --inProgress: #ffcd91;
  --done: #84cb79;
  --urgent: #ff7272;
  --low: #72d5ff;
  --lightGray: #6d6d6d;
  --extraLightGray: #b5b5b5;
  --light-md: #333333;
}

.c-primary {
  background-color: var(--primary) !important;
}

.c-secondary {
  background-color: var(--secondary) !important;
}

.c-light {
  background-color: var(--light) !important;
}

.c-light-md {
  background-color: var(--light-md) !important;
}

.c-light-sm {
  background-color: var(--light-sm) !important;
}

.c-dark {
  background-color: var(--dark) !important;
}

.c-light-primary {
  background-color: var(--light-primary) !important;
}

.c-success {
  background-color: var(--success) !important;
}

.c-error {
  background-color: var(--error) !important;
}

.c-white {
  background-color: var(--white) !important;
}

.c-primary-opacity {
  background-color: var(--primary-opacity) !important;
}

.c-extraLightGray {
  background-color: var(--extraLightGray) !important;
}

.txt-primary {
  color: var(--primary) !important;
}

.txt-secondary {
  color: var(--secondary) !important;
}

.txt-light {
  color: var(--light) !important;
}

/* .txt-light-md {
  color: var(--light-md) !important;
} */

.txt-dark {
  color: var(--dark) !important;
}

.txt-error {
  color: var(--error) !important;
}

.txt-white {
  color: var(--white) !important;
}

.txt-yellow {
  color: var(--yellow) !important;
}

.txt-success {
  color: var(--sucess) !important;
}

.txt-light-sm {
  color: var(--light-sm) !important;
}

/*  Radius */
.border-l {
  border-radius: var(--border-radius-large);
}

.border-n {
  border-radius: var(--border-radius-normal);
}

.border-s {
  border-radius: var(--border-radius-small);
}

.border-1 {
  border: 1px solid rgba(14, 52, 75, 0.1);
}

.border-1_5 {
  border: 1.5px solid rgba(14, 52, 75, 0.1);
}

.border-2 {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

/* Box Shadow */
.b-shadow {
  box-shadow: var(--b-shadow);
}

/* Text Wrap */
.text-wrap {
  word-wrap: break-word;
}

.AdminIndex [type="checkbox"],
.CustomerIndex [type="checkbox"],
.ant-modal [type="checkbox"] {
  font-size: 22px;
}

.cursor-pointer-default {
  cursor: default !important;
}

/* Admin - Dashboard */
.c-lightBlue {
  background-color: var(--lightBlue) !important;
}

.c-lightRed {
  background-color: var(--lightRed) !important;
}

.c-lightGreen {
  background-color: var(--lightGreen) !important;
}

.c-lightPurple {
  background-color: var(--lightPurple) !important;
}

.c-pending {
  background-color: var(--pending) !important;
}

.c-inProgress {
  background-color: var(--inProgress) !important;
}

.c-done {
  background-color: var(--done) !important;
}

.c-urgent {
  background-color: var(--urgent) !important;
}

.c-low {
  background-color: var(--low) !important;
}

.c-sky {
  background-color: #589dc2 !important;
}

.txt-blue {
  color: var(--blue) !important;
}
.txt-red {
  color: var(--red) !important;
}

.txt-green {
  color: var(--green) !important;
}
.txt-purple {
  color: var(--purple) !important;
}

.txt-lightGray {
  color: var(--lightGray) !important;
}

.txt-light-md {
  color: var(--light-md);
}

.txt-inProgress {
  color: var(--inProgress);
}

.txt-pending {
  color: var(--pending);
}

.txt-done {
  color: var(--done);
}
